<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Template Actions</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious"
                        icon="pi pi-chevron-left" />
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm"
                        iconPos="right" />
                </div>
            </div>
            <div class="card">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Client Name : {{ this.localClientName ? this.localClientName : '-' }} </h6>
                    </span>
                </div>
                <DataTable :value="templateactionlist" v-model:selection="selectedProduct" :lazy="true"
                    :paginator="true" :rows="30" :totalRecords="totalRecords" :loading="loading" @page="onPage($event)"
                    class="p-datatable-users" data-key="td1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Template Actions</h5>
                        </template>
                        <template v-slot:right v-if="this.clientList.length > 0">
                            <Button label="Copy to other client  " icon="pi pi-copy" class="p-mr-2"
                                @click="onSelectWhatsappSettings" style="margin: right 3.5rem;"
                                :disabled="this.selectedProduct.length == 0" />
                        </template>
                        <!-- <template v-slot:right>
                            <Button label="Add Plan" icon="pi pi-plus" class=" p-mr-2" @click="addFundDialogOpen" />
                        </template> -->
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <!-- <Column header="Sr." headerStyle="width: 15%">
                        <template #body="{ data }">
                            <div>
                                <div>{{ data.td1 }}</div>
                            </div>
                        </template>
                    </Column> -->
                    <Column selectionMode="multiple" headerStyle="width: 5%" v-if="this.clientList.length > 0"></Column>
                    <Column header="Sr." headerStyle="width: 4%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column field="name" header="Action Name" headerStyle="width: 50%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.td2 ? data.td2 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="Unique Id" headerStyle="width: 15%">
                        <template #body="{ data }">
                            <div> {{ data.td3 }}</div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 20%">
                        <template #body="{ data }">
                            <Button label="Edit Event" class="p-button-link" @click="templateActionsRedirect(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- Copy Settings dialog start here -->
    <Dialog v-model:visible="isCopyWhatsappSetting" :style="{ width: '600px' }" header="Copy WhatsApp Settings"
        :modal="true" class="p-fluid">
        <div class="p-field p-col-12 p-md-9">
            <label for="client_name">
                Select Client
            </label>
            <Dropdown v-model="client_name" :options="clientList" optionLabel="label" appendTo="body"
                class="p-text-capitalize" placeholder="Select client" />
        </div>
        <template #footer>
            <Button v-if="!copysettingloader" label="Submit" icon="pi pi-check" class="p-button-text"
                :disabled="client_name == null || client_name == ''" @click="onCopySettingSubmit" style="width: 90px" />
            <Button v-show="copysettingloader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button>
        </template>
    </Dialog>
    <!-- Copy Settings dialog end here -->
</template>
<script>
import ApiService from '../../service/ApiService';
import router from '@/router';
import { useRoute } from 'vue-router';
export default {
    inject: ['global'],
    data() {
        return {
            templateactionlist: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            localActionId: '',
            localClientId: '',
            localClientName: '',
            isCopyWhatsappSetting: false,
            selectedProduct: [],
            copysettingloader: false,
            clientList: [],
            selectedWhatsAppSettings: [],
            client_name: '',
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        const route = useRoute();
        this.routeParam = route.params.clientSubId;
        this.localClientName = localStorage.localClientName;
        this.loading = true;
        this.getTemplateActions({ page_no: this.page_no });
        if (localStorage.localActionId) {
            this.localActionId = localStorage.localActionId;
        }
        if (localStorage.localClientId) {
            this.localClientId = localStorage.localClientId;
        }
    },

    watch: {
        localActionId(actionId) {
            localStorage.localActionId = actionId;
        },
        localClientId(clientId) {
            localStorage.localClientId = clientId;
        }
    },

    methods: {
        onSelectWhatsappSettings() {
            this.ApiService.getClientListOnlyExceptGivenId({ clientId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.clientList = data.data;
                } else {
                    this.clientList = null;
                }
            });
            this.isCopyWhatsappSetting = true;
        },
        getTemplateActions(ev) {
            this.loading = true;
            this.ApiService.getTemplateActions(ev).then((data) => {
                if (data.status == 200) {
                    this.templateactionlist = data.data;
                    this.totalRecords = data.count;
                } else {
                    this.templateactionlist = '';
                    this.totalRecords = 0;
                }
                this.loading = false;
            });
        },

        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getTemplateActions({ page_no: event.page });
        },

        templateActionsRedirect(e) {
            router.push({
                name: "listemplateevent",
                // params: { actionId: e.td1 },
            });
            this.localActionId = e.td1;
            this.localClientId = this.routeParam;
        },
        onCopySettingSubmit() {
            this.copysettingloader = true;
            var formData = new FormData();
            if (this.selectedProduct) {
                let vals = this.selectedProduct.map(function (item) {
                    return item.td3;
                });

                this.selectedWhatsAppSettings = vals;
                formData.append("selectedActionIds", this.selectedWhatsAppSettings);
            }
            formData.append("clientId", this.routeParam);
            formData.append("CopyIntoClientId", this.client_name.value);
            this.ApiService.copyTemplateActionToOtherClient(formData).then((items) => {
                if (items.success == true) {
                    var successMsg = items.message;
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    this.getTemplateActions();
                    this.copysettingloader = false;
                    this.isCopyWhatsappSetting = false;
                    this.client_name = "";
                    this.selectedProduct = []
                } else {
                    var errorMsg = items.message;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                }
            });
        },

        goToDashboard() {
            router.push({
                name: "clientdashboard",
                params: { clientId: this.routeParam },

            });
        },
        goToPrevious() {
            router.push({
                name: "add-debit",
                params: { clientSubId: this.routeParam },

            });
        },
        goToNext() {
            router.push({
                name: "whatsapp-template-settings",
                params: { clientSubId: this.routeParam },

            });
        },

    },
};
</script>
<style scoped lang="scss">
.plan-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-free {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-paid {
        background: #ffd8b2;
        color: #805b36;
    }
}

.plan-type-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-standard {
        background: #b3e5fc;
        color: #23547b;
    }

    &.status-premium {
        background: #eccfff;
        color: #694382;
    }
}
</style>
